import React from 'react';
import styled from 'styled-components';
import Cliente from './Cliente';
import hughesNet from '../../assets/hughesnet.png'
import indatech from '../../assets/indatech.jpg'
import ingecall from '../../assets/ingecall.png'
import skynet from '../../assets/skynet.png'
import axess from '../../assets/axess.gif'
import { Title } from './ClienteStyles';
import dinatal from '../../assets/dinatel.webp'
import panamproject from '../../assets/panamproject.png'
import teseract from '../../assets/teseract.png'
import districad from '../../assets/districed.jpg'
const Clientes = () => {

    const ClientesContainer = styled.div`
        background-color:#f2f3ee;
      
        section{
            display: flex;
            flex-wrap: wrap; /* Envolver elementos en filas adicionales */
            justify-content: center; 
            gap:10px;
        }
    `;
    const clientesData = [
        { logo: indatech, nombre: 'INDATECH' },
        { logo: ingecall, nombre: 'INGECALL' },
        { logo: teseract, nombre: 'TESERACT' },
        { logo: panamproject, nombre: 'PANAMPROJECT' },
        { logo: hughesNet, nombre: 'HUGNESNET' },
        { logo: districad, nombre: 'DISTRICED' },
        { logo: skynet, nombre: 'SKYNET COLOMBIA' },
        { logo: dinatal, nombre: 'DINATEL GRUOP' },
        { logo: axess, nombre: 'AXESAT S.A.S' },
        // Agrega más clientes aquí
    ];

    return (
        <ClientesContainer id='clientes'>
            <Title>Nuestros Clientes </Title>.
            <section>
                {clientesData.map((cliente, index) => (
                    <Cliente key={index} logo={cliente.logo} nombre={cliente.nombre} />
                ))}
            </section>
        </ClientesContainer>
    )
}

export default Clientes