import React from 'react'
import { DivServicios, SectionServicios, Title } from './ServiciosStyles'
import muestra from '../../assets/muestra-servicio.webp'
import solar from '../../assets/Solar.jpg'
import mantto from '../../assets/mantto.jpg'
import computer from '../../assets/computer.jpg'
import estudios from '../../assets/estudios.jpg'
import service from '../../assets/assistencia-.jpg'
import { useState } from 'react'
import { ModalSolucionesEnergeticas } from '../modals/ModalSolucionesEnergeticas'
import { ModalTelecomunicaciones } from '../modals/ModalTelecomunicaciones'
import { ModalInterventorias } from '../modals/ModalInterventorias'
import { ModalConsultoriaInformatica } from '../modals/ModalConsultoriaInformatica'
import { ModalInformacion } from '../modals/ModalInformacion'


const Servicios = () => {
    const [show, setShow] = useState(false);
    const [showTele, setShowTele] = useState(false);
    const [showInterventorias, setShowInterventorias] = useState(false);
    const [showConsultoria, setShowConsultoria] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    return (
        <div id="servicios">
            <Title>Nuestros Servicios</Title>
            <DivServicios>
                <SectionServicios onClick={() => setShow(true)}>
                    <section>
                        <img src={solar} alt="SOLUCIONES ENERGÉTICAS" />
                        <h2>SOLUCIONES ENERGÉTICAS</h2>
                    </section>
                </SectionServicios>
                <SectionServicios onClick={() => setShowTele(true)}>
                    <section>
                        <img src={muestra} alt="Telecomunicaciones" />
                        <h2>Telecomunicaciones</h2>
                    </section>
                </SectionServicios>
                <SectionServicios onClick={() => setShowTele(true)}>
                    <section>
                        <img src={mantto} alt="mantenimientos" />
                        <h2>mantenimientos</h2>
                    </section>
                </SectionServicios>
                <SectionServicios onClick={() => setShowInterventorias(true)}>
                    <section>
                        <img src={estudios} alt="Interventorías" />
                        <h2>Interventorías</h2>
                    </section>
                </SectionServicios>
                <SectionServicios onClick={() => setShowConsultoria(true)}>
                    <section>
                        <img src={service} alt="Consultoría informática" />
                        <h2>Consultoría informática</h2>
                    </section>
                </SectionServicios>
                <SectionServicios onClick={() => setShowInfo(true)}>
                    <section>
                        <img src={computer} alt="Comercio y suministro" />
                        <h2>Comercio y suministro</h2>
                    </section>
                </SectionServicios>
            </DivServicios>
            <ModalSolucionesEnergeticas
                show={show}
                setShow={setShow}
            />
            <ModalTelecomunicaciones
                showTele={showTele}
                setShowTele={setShowTele}
            />
            <ModalConsultoriaInformatica
                showConsultoria={showConsultoria}
                setShowConsultoria={setShowConsultoria}
            />
            <ModalInterventorias
                showInterventorias={showInterventorias}
                setShowInterventorias={setShowInterventorias}
            />
            <ModalInformacion
                showInfo={showInfo}
                setShowInfo={setShowInfo}
            />
        </div>
    )
}

export default Servicios