import React from 'react'
import { Card, CardGroup, Modal, ModalFooter } from 'react-bootstrap'
import solar1 from '../../assets/12.jpg'
import solar2 from '../../assets/13.jpg'
import solar3 from '../../assets/14.jpg'
import solar4 from '../../assets/15.jpg'
import { scroller } from 'react-scroll'

export const ModalSolucionesEnergeticas = ({ show, setShow }) => {
  const handleScrollToSection = (section) => {
    const offset = -80;
    scroller.scrollTo(section, {
      duration: 500,
      smooth: 'easeInOutQuart',
      offset,
    });
    setShow(false)
  };
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className='modal-xl'
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          <h1 className='text-capitalize fw-bold'>Soluciones energéticas</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-light px-3'>
        <section className='d-flex justify-content-around align-items-center'>
          <h2 className='fw-bold fs-1 w-50'>Ventas e instalación de sistemas de paneles solares.</h2>
          <div className='w-50'>
            <img src={solar1} alt="Portada Panel Solar" className='w-75' />
          </div>
        </section>
        <section>
          <CardGroup>
            <Card>
              <Card.Img variant="top" src={solar3} style={{ height: '18rem' }} />
              <Card.Body>
                <Card.Text className='fs-3'>
                  Sistema de panel solar sin interconexión a la red eléctrica.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Img variant="top" src={solar4} style={{ height: '18rem' }} />
              <Card.Body>
                <Card.Text className='fs-3'>
                  Servicios de interconexión.
                </Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </section>
        <section className='d-flex py-5'>
          <h2 className='fs-1'>Ofrecemos la mejor relación precio calidad del servicio.</h2>
          <div className='d-flex align-items-button'>
            <img src={solar2} alt="Portada Panel Solar" />
          </div>
        </section>
        <ModalFooter>
          <button className='btn btn-primary'onClick={()=>handleScrollToSection("contacto")}>Contactanos</button>
          <button className='btn btn-secondary' onClick={() => setShow(false)}>Volver</button>
        </ModalFooter>
      </Modal.Body>
    </Modal>
  )
}
