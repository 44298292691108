import React from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import consutasI from '../../assets/service-computers.jpg'
import { scroller } from 'react-scroll';
export const ModalConsultoriaInformatica = ({ showConsultoria, setShowConsultoria }) => {
  const handleScrollToSection = (section) => {
    const offset = -80;
    scroller.scrollTo(section, {
      duration: 500,
      smooth: 'easeInOutQuart',
      offset,
    });
    setShowConsultoria(false)
  };
  return (
    <Modal
      show={showConsultoria}
      onHide={() => setShowConsultoria(false)}
      className='modal-xl'
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton >
        <Modal.Title id="example-custom-modal-styling-title">
          <h1 className='text-capitalize fw-bold'>Consultoría Informática</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-light px-3'>
        <section className='d-flex justify-content-around align-items-center'>

          <div className='d-flex mt-5'>
            <img src={consutasI} alt="Redes de Telecomunicaciones" className='m-3 w-50 rounded' />
            <div>
              <h3 className='fs-3 text-capitalize fw-bold'>Consultoría Informática</h3>
              <hr className="my-2 border border-success" style={{ width: "100%" }} />
              <p className='mt-3 text-justify'>
                Nuestra Consultoría Informática brinda soluciones a medida para tus desafíos tecnológicos. Con un enfoque estratégico, colaboramos contigo para identificar oportunidades de mejora y optimización en tus sistemas y procesos informáticos. Nuestro equipo de expertos en TI te asesora en la selección de tecnologías adecuadas, el diseño de arquitecturas eficientes y la implementación de soluciones innovadoras. Ya sea que necesites una evaluación de seguridad, una migración de plataformas o la implementación de soluciones en la nube, estamos aquí para llevar tus operaciones informáticas al siguiente nivel.
              </p>
            </div>
          </div>
        </section>

        <ModalFooter>
          <button className='btn btn-primary' onClick={()=>handleScrollToSection("contacto")}>Contáctanos</button>
          <button className='btn btn-secondary' onClick={() => setShowConsultoria(false)}>Volver</button>
        </ModalFooter>
      </Modal.Body>
    </Modal>
  );
};
