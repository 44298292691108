import React from 'react';
import { ClienteContainer } from './ClienteStyles';

const Cliente = ({ logo, nombre }) => {
  return (
    <ClienteContainer>
      <img src={logo} alt={nombre} />
      <h3>{nombre}</h3>
    </ClienteContainer>
  );
};

export default Cliente;
