import React from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import telecomImage from '../../assets/4.jpg';
import estudiosImage from '../../assets/10.jpg';
import manttoImage from '../../assets/9.jpg';
import sateliteImage from '../../assets/20.jpg';
import hugesImage from '../../assets/18.jpg';
import { scroller } from 'react-scroll';
export const ModalTelecomunicaciones = ({ showTele, setShowTele }) => {
  const handleScrollToSection = (section) => {
    const offset = -80;
    scroller.scrollTo(section, {
      duration: 500,
      smooth: 'easeInOutQuart',
      offset,
    });
    setShowTele(false)
  };
  return (
    <Modal
      show={showTele}
      onHide={() => setShowTele(false)}
      className='modal-xl'
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          <h1 className='text-capitalize fw-bold'>Telecomunicaciones</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-light px-3'>
        <section className='d-flex justify-content-around align-items-center'>
          <p className='mb-3'>
            Ofrecemos servicios expertos en asesoría técnica, supervisión de proyectos,
            montaje y mantenimiento, tanto predictivo como correctivo. Brindamos respaldo
            en la realización o concepción de iniciativas vinculadas a comunicaciones, sistemas
            de conexión y campos informáticos. Ya sea llevando a cabo labores directivas y operativas,
            o proveyendo profesionales altamente capacitados en este ámbito.
          </p>
        </section>
        <section>
          <div className='d-flex'>
            <img src={telecomImage} alt="Redes de Telecomunicaciones" className='me-3 w-50 rounded' />
            <div>
              <h3 className='fs-5 text-capitalize fw-bold'>Implementación de Proyectos</h3>
              <hr className="my-2 border border-success" style={{ width: "100%" }} />
              <p className='mt-3 text-justify'>
                En el ámbito de la Implementación de Proyectos, nuestro respaldo técnico es una valiosa herramienta. Contamos con un equipo altamente calificado, dispuesto a ofrecer soluciones adaptadas a las necesidades de cada cliente, ya sea de forma presencial o remota. Nuestros expertos dominan diversas tecnologías, permitiéndonos afrontar retos y diseñar estrategias específicas para cada proyecto. Esta capacidad de asistencia flexible garantiza una implementación sin problemas y eficiente. Trabajamos en estrecha colaboración con nuestros clientes para asegurar resultados exitosos y sobresalientes en cada proyecto.
              </p>
            </div>
          </div>
          <div className='d-flex mt-5'>
            <div>
              <h3 className='fs-5 text-capitalize fw-bold'>Mantenimientos Correctivos y Preventivos</h3>
              <hr className="my-2 border border-danger" style={{ width: "100%" }} />
              <p className='mt-3 text-justify'>
                Estamos disponibles las 24 horas del día, los 7 días de la semana, para atender cualquier
                solicitud que requieras. Nuestra experiencia se refleja en los mantenimientos que realizamos,
                tanto correctivos como preventivos, siguiendo estándares de calidad excepcionales que garantizan
                el logro de los resultados deseados en tus redes de telecomunicaciones, enlaces de radio, sistemas
                satelitales y otros equipos terminales. Ampliando nuestras capacidades, también abarcamos servicios
                de migración tecnológica, así como reubicaciones y traslados de equipos, dentro de nuestro completo
                portafolio de soluciones de mantenimiento.
              </p>
            </div>
            <img src={manttoImage} alt="Redes de Telecomunicaciones" className='ms-3 w-50 rounded' />
          </div>
          <div className='d-flex mt-5'>
            <img src={estudiosImage} alt="Redes de Telecomunicaciones" className='m-3 w-50 rounded' />
            <div>
              <h3 className='fs-5 text-capitalize fw-bold'>estudios de campos</h3>
              <hr className="my-2 border border-primary" style={{ width: "100%" }} />
              <p className='mt-3 text-justify'>
                Brindamos un análisis integral que abarca el diagnóstico físico, técnico, espacial
                y socioambiental. Este análisis evalúa la viabilidad de tu proyecto, abordando aspectos
                como la adaptación de espacios, la implementación de infraestructura y la selección de
                materiales adecuados. Nuestro objetivo es evitar posibles errores y
                retrasos que puedan obstaculizar la implementación exitosa de tu proyecto.
              </p>
            </div>
          </div>
          <div className='d-flex mt-5'>
            <div>
              <h3 className='fs-5 text-capitalize fw-bold'>Internet Satelital</h3>
              <hr className="my-2 border border-warning" style={{ width: "100%" }} />
              <p className='mt-3 text-justify'>
                En el mundo interconectado de hoy, ofrecemos soluciones de Internet Satelital que se adaptan
                a tus necesidades. Nuestro enfoque integral abarca desde la instalación hasta el mantenimiento continuo,
                brindando una conectividad confiable donde más lo necesitas. Además, llevamos a cabo estudios de campo
                exhaustivos para garantizar la optimización de la señal y la cobertura en cada ubicación.
                Con una amplia experiencia en el campo de las comunicaciones, estamos comprometidos a ofrecer
                servicios de Internet Satelital que superen tus expectativas y te mantengan conectado en todo
                momento
              </p>
              <img src={hugesImage} alt="Redes de Telecomunicaciones" className='m-3 w-50 rounded' />
            </div>
            <img src={sateliteImage} alt="Redes de Telecomunicaciones" className='m-3 w-50 rounded' />
          </div>
        </section>
        <ModalFooter>
          <button className='btn btn-primary' onClick={()=>handleScrollToSection("contacto")}>Contáctanos</button>
          <button className='btn btn-secondary' onClick={() => setShowTele(false)}>Volver</button>
        </ModalFooter>
      </Modal.Body>
    </Modal>
  );
};
