import { styled, keyframes } from 'styled-components';


export const appearAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const SectionWrapper = styled.section`
  background-color: #f2f2f2;
  padding: 20px 50px 20px 50px;
  animation: ${appearAnimation} 1s ease-in-out;
  /* Estilos de fondo y bordes */
  background-image: linear-gradient(110deg, #1798f5 50%, #25eaf7 50%);
  background-size: 100% 100%;

  /* Pseudo-elemento para la media luna */
  &::after {
    content: '';
    background-color: #fcfa51; /* Color de la media luna */
    border-radius: 50%; /* Hace que la forma sea circular */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Agrega una sombra */
  }
  figure{
    padding-top: 10px;
    width:40%;
    img{
      width:100% ;
     height:100%;
     object-fit:cover ;
     border-radius:10px ;
    }
  }
  @media only screen and (max-width: 400px) {
      figure{
        margin:0 ;
        width:100% ;
      }
  }
`;


export const Title = styled.h2`
  color: #fcfa51;
  font-size: 34px;
  display:flex ;
  justify-content:center ;
  font-weight:bold ;
`;

export const Paragraph = styled.p`
    color: #FFFFFF;
    font-size: 16px;
    line-height: 1.5;
    text-align:justify;
    padding-top: 10px;
`;
export const SeccionNosostros = styled.section`
    display:flex ;
    justify-content:space-between ;
    gap:20px ;
    article{
      flex:1 ;
    }
    @media screen and (max-width: 400px) {
      flex-direction:column-reverse ; 
  }
`