import React from 'react';
import { SectionWrapper, Title, Paragraph, SeccionNosostros } from './NosotrosStyles';
import nosotros from '../../assets/7.jpg'
const Nosotros = () => {


  return (
    <SectionWrapper id='nosotros'>
      <Title>Nosotros</Title>
      <SeccionNosostros>
        <article>
          <Paragraph>Conexión21 S.A.S. Es una sociedad por
            acciones simplificas por sus siglas S.A.S, quien
            en sus giros de negocios se enfoca en dar
            soluciones de implementación e instalación de
            servicios energéticos a través de paneles
            solares, venta, instalación, y mantenimiento
            de redes de Telecomunicaciones terrestres y
            satelitales, Softwares, y tecnología
            blockclain. </Paragraph>
          <Paragraph>
            Conexion21 S.A.S, empresa con mas de 2
            años en el mercado, ofreciendo soluciones en
            conectividad. llegando a lugares de difícil
            acceso. Somo la mejor alternativa para tus
            necesidades, Atendemos colegios, escuelas,
            PYME, instituciones de gobierno y hogares,
            siempre dando la mejor relación precio vs
            calidad del servicio. Somos una empresas
            comprometidas en mejorar la experiencia de
            los clientes a través de pedidos precisos,
            convenientes y de entrega puntal.
          </Paragraph>
        </article>
        <figure>
          <img src={nosotros} alt="Imagen de Nosotros" />
        </figure>
      </SeccionNosostros>
    </SectionWrapper>
  );
};

export default Nosotros