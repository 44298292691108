import React, { useState } from 'react';
import { ContactFormContainer, FormButton, FormInput, FormLabel, FormTextArea, Title, Rows, ButtonContainer } from './ContactosStyles';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { Icon } from '@iconify/react';

const Contactos = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    message: ""
  });
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isCaptchaVerified) {
      alert("Por favor, resuelve el captcha correctamente.");
      return;
    }

    try {
      await axios.post('https://formspree.io/f/xqkvqgav', formValues);
      alert("Mensaje enviado correctamente");
      setFormValues({
        name: "",
        lastName: "",
        email: "",
        phone: "",
        message: ""
      });
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
      alert("Ocurrió un error al enviar el mensaje");
    }
  };

  const onChange = (value) => {
    if (value) {
      setIsCaptchaVerified(true);
    }
  };

  return (
    <ContactFormContainer id='contacto'>
      <Title>Contactos</Title>
      <div className='d-flex justify-content-around'>
        <section id="informcioncontacto" className='mb-3'>
          <h2 className='fs-2 text-capitalize fw-bold mb-3'>Información de Contacto</h2>
          <p><b>Dirección: </b> Quibdó, Chocó, Colombia</p>
          <p><b>Teléfono:</b> <a href="tel:+571234567890">(+57) 3133663699</a></p>
          <p><b>Correo Electrónico: </b><a href="mailto:contactos@conexion21.com.co">contactos@conexion21.com.co</a></p>
          <div className="d-flex justify-content-around mt-5">
            <a href="#" target='_blank'><Icon icon="logos:facebook" width="50" /></a>
            <a href="#" target='_blank'><Icon icon="fa6-brands:square-x-twitter" width="50" /></a>
            <a href="#" target='_blank'><Icon icon="skill-icons:instagram" width="50" /></a>
            <a href="#" target='_blank'><Icon icon="ps:whatsapp" color="green" width="50" /></a>
          </div>
        </section>
        <section className='mb-3'>
          <form onSubmit={handleSubmit}>
            <Rows>
              <div>
                <FormLabel htmlFor="name">Nombre:</FormLabel>
                <FormInput
                  type="text"
                  id="name"
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <FormLabel htmlFor="lastName">Apellido:</FormLabel>
                <FormInput
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </Rows>
            <Rows>
              <div>
                <FormLabel htmlFor="email">Email:</FormLabel>
                <FormInput
                  type="email"
                  id="email"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <FormLabel htmlFor="phone">Teléfono:</FormLabel>
                <FormInput
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formValues.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </Rows>
            <Rows>
              <div>
                <FormLabel htmlFor="message">Escribe tu mensaje aquí:</FormLabel>
                <FormTextArea
                  id="message"
                  name="message"
                  value={formValues.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <ReCAPTCHA
                  sitekey="6LdXZV8nAAAAALyeLjNjVHAd4n_-p12ex3q782R1"
                  onChange={onChange}
                />
              </div>
            </Rows>
            <ButtonContainer>
              <FormButton type="submit">Enviar</FormButton>
            </ButtonContainer>
          </form>
        </section>
      </div>


    </ContactFormContainer>
  );
}

export default Contactos;
