import React from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import computer from '../../assets/PC.JPG'
import portatil from '../../assets/Portatil.JPG'
import impresora from '../../assets/Impresora.JPG'
import { scroller } from 'react-scroll';
export const ModalInformacion = ({ showInfo, setShowInfo }) => {
  const handleScrollToSection = (section) => {
    const offset = -80;
    scroller.scrollTo(section, {
      duration: 500,
      smooth: 'easeInOutQuart',
      offset,
    });
    setShowInfo(false)
  };
  return (
    <Modal
      show={showInfo}
      onHide={() => setShowInfo(false)}
      className='modal-xl'
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          <h1 className='text-capitalize fw-bold'>Comercio y suministro</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-3'>
        <section className='d-flex justify-content-around align-items-center'>
          <p className='mb-3'>
            Nos especializamos en el comercio y suministro, tanto al por mayor como al detalle, de partes y piezas electrónicas y de telecomunicaciones. Contamos con un amplio catálogo que abarca desde componentes esenciales hasta dispositivos especializados. Nuestro enfoque es brindarte soluciones precisas y de alta calidad para tus proyectos electrónicos y de telecomunicaciones. Ya sea que necesites una sola pieza o un suministro a gran escala, estamos aquí para satisfacer tus necesidades.
          </p>
        </section>
        <section>
          <div className='d-flex '>
            <img src={computer} alt="Redes de Telecomunicaciones" className='me-3 w-75 rounded' />
            <div className=''>
              <h3 className='fs-5 text-capitalize fw-bold'>EQUIPOS DE ESCRITORIO</h3>
              <hr className="my-2 border border-success" style={{ width: "100%" }} />
            </div>
          </div>
          <div className='d-flex m-5'>
            <div className=''>
              <h3 className='fs-5 text-capitalize fw-bold'>EQUIPOS PERSONALES.</h3>
              <hr className="my-2 border border-danger" style={{ width: "100%" }} />
            </div>
            <img src={portatil} alt="Redes de Telecomunicaciones" className='me-3 w-75 rounded' />
          </div>
          <div className='d-flex m-5'>
          <img src={impresora} alt="Redes de Telecomunicaciones" className='me-3 w-75 rounded' />
            <div className=''>
              <h3 className='fs-5 text-capitalize fw-bold'>Impresoras Multifuncional.</h3>
              <hr className="my-2 border border-warning" style={{ width: "100%" }} />
            </div>
          </div>
        </section>
        <ModalFooter>
          <button className='btn btn-primary'onClick={()=>handleScrollToSection("contacto")}>Contáctanos</button>
          <button className='btn btn-secondary' onClick={() => setShowInfo(false)}>Volver</button>
        </ModalFooter>
      </Modal.Body>
    </Modal>
  );
};
