import { styled } from "styled-components";


export const BannerDiv = styled.div`
height:100% ;
`

export const Figure = styled.figure`
width:100% ;
height:100vh ;
 
img{
    width:100% ;
    height:100% ;
    object-fit:cover ;
    overflow: hidden;
}
`
export const Texto = styled.div`
width:100% ;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: linear-gradient(to right, #fcfa51,#1798f5, #fcfa51,#25eaf7); /* Degradado de color para el texto */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  h1 {
    font-size:55px ;
    font-weight:bolder;
  }
  b {
    font-size:25px ;
    font-weight:bolder;
    font-style:italic ;
   
  }
`