import React, { useState } from "react";
import { ContainerHeader, ContainerHeaderLinks, ContainerHeaderLinksUnt, ContainerHeaderLogo } from "./StyledHeaderStudents";
import BurguerButton from "./BurguerButton";
import { Icon } from "@iconify/react";
import {BrowserRouter as Router,NavLink } from "react-router-dom";
import logo from '../../assets/logo.png';
import { scroller } from 'react-scroll';
const Header = () => {
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked(!clicked);
  };
  const handleScrollToSection = (section) => {
    const offset = -80;
    scroller.scrollTo(section, {
      duration: 500,
      smooth: 'easeInOutQuart',
      offset,
    });
  };
  return (
    <Router>
      <div id="home"></div>
      <ContainerHeader >
        <ContainerHeaderLogo>
          <figure>
            <img src={logo} alt="logo" />
          </figure>
        </ContainerHeaderLogo>
        <ContainerHeaderLinks active={clicked}>
          <ContainerHeaderLinksUnt>
            <figure>
              <Icon icon="mdi-light:home" width="20" />
              <NavLink
                to={"/home"}
                className={({ isActive, isPending }) =>
                  isPending ? "navlink" : isActive ? "navlink active" : "navlink"
                }
                onClick={() => handleScrollToSection('home')}
              >
                Inicio
              </NavLink>
            </figure>
          </ContainerHeaderLinksUnt>
          <ContainerHeaderLinksUnt>
            <figure>
              <Icon icon="la:people-carry" width="20" />
              <NavLink
                to={"/nosotros"}
                className={({ isActive, isPending }) =>
                  isPending ? "navlink" : isActive ? "navlink active" : "navlink"
                }
                onClick={() => handleScrollToSection('nosotros')}
              >
                Nosotros
              </NavLink>
            </figure>
          </ContainerHeaderLinksUnt>
          <ContainerHeaderLinksUnt>
            <figure>
              <Icon icon="fluent-mdl2:c-r-m-services" width="20" />
              <NavLink
                to={"/servicios"}
                className={({ isActive, isPending }) =>
                  isPending ? "navlink" : isActive ? "navlink active" : "navlink"
                }
                onClick={() => handleScrollToSection('servicios')}
              >
                Servicios
              </NavLink>
            </figure>
          </ContainerHeaderLinksUnt>
          <ContainerHeaderLinksUnt>
            <figure>
              <Icon icon="pepicons-pencil:people-circle" width="20" />
              <NavLink
                to={"/clientes"}
                className={({ isActive, isPending }) =>
                  isPending ? "navlink" : isActive ? "navlink active" : "navlink"
                }
                onClick={() => handleScrollToSection('clientes')}
              >
                Nuestros Clientes
              </NavLink>
            </figure>
          </ContainerHeaderLinksUnt>
          <ContainerHeaderLinksUnt>
            <figure>
              <Icon icon="material-symbols:attach-email-outline-sharp" width="20" />
              <NavLink
                to={"/contacto"}
                className={({ isActive, isPending }) =>
                  isPending ? "navlink" : isActive ? "navlink active" : "navlink"
                }
                onClick={() => handleScrollToSection('contacto')}
              >
                Contactenos
              </NavLink>
            </figure>
          </ContainerHeaderLinksUnt>
          <ContainerHeaderLinksUnt>
            <figure>
              <Icon icon="material-symbols:attach-email-outline-sharp" width="20" />
              <NavLink
                to={"https://mail.hostinger.com/?clearSession"}
                className={({ isActive, isPending }) =>
                  isPending ? "navlink" : isActive ? "navlink active" : "navlink"
                }
                target="_blank"
                onClick={() => handleScrollToSection('portal')}
              >
                Portal Colaboradores
              </NavLink>
            </figure>
          </ContainerHeaderLinksUnt>
        </ContainerHeaderLinks>
        <div className="burguer" >
          <BurguerButton clicked={clicked} handleClick={handleClick} />
        </div>
      </ContainerHeader>
    </Router>
  );
};

export default Header;
