
import fondo from '../../assets/cielo_azul.jpg';
import { BannerDiv, Figure, Texto } from './BannerStyle';

const Banner = () => {

  return (
    <BannerDiv >
      <Figure>
        <img src={fondo} alt="Banner Principal" />
      </Figure>
      <Texto >
        <h1>
          Bienvenido a Conexion21
        </h1>
        <b>Redes, Telecomunicaciones E Internet Satelital</b>
      </Texto>
    </BannerDiv>
  );
};

export default Banner;
