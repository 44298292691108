import React from 'react'
import './index.css'
import { GlobalStyles } from './GlobalStyles'
import Banner from './components/banner/Banner'
import Nosotros from './components/nosotros/Nosotros'
import Servicios from './components/servicios/Servicios'
import Clientes from './components/clientes/Clientes'
import Footer from './components/footer/Footer'
import Contactos from './components/contactos/Contactos'
import Header from './components/header/Header'
import FloatingButton from './components/FloatingButton'





const App = () => {


  return (
    <>
      <GlobalStyles />
      <Header />
      <Banner />
      <Nosotros />
      <Servicios />
      <Clientes />
      <Contactos />
      <Footer />
      <FloatingButton />
    </>
  )
}

export default App