import { styled } from "styled-components";


export const GlobalStyles = styled.div`@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200&display=swap');
margin:0 ;
padding:0 ;
list-style:none;
text-decoration:none;
box-sizing:border-box;


`


export const Container = styled.div`

`