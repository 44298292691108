import { styled } from "styled-components";

export const ContainerHeader = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0);
  background: linear-gradient(to right, #fff,#1798f5, #25eaf7);
  color: white;
  height: 70px;
  font-size: 14px;
  position:fixed ;
  width:100%;
  a {
    text-decoration: none;
    color: white;
    font-size:16px ;
  }

  @media screen and (max-width: 769px) {
    font-size: 12px;
    justify-content: space-between;
  }

  @media screen and (max-width: 474px) {
  }

  .links {
    display: none;
  }

  &.active .links {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
  }

  .burguer {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
`;

export const ContainerHeaderLogo = styled.div`
  display: flex;

  img {
    width: 120px;
  }
`;

export const ContainerHeaderLinks = styled.div`
  display: flex;

  @media screen and (max-width: 769px) {
    flex-direction: column;
    position: absolute;
    background-color: #03203a;
    z-index: 100;
    display: flex;
    justify-content: space-evenly;
    top: 7%;
    width: 100%;
    border-radius: 0 0 253px 10px;
    font-size: 18px;
    display: ${(props) => (props.active ? "flex" : "none")};
  }

  @media screen and (max-width: 474px) {
    border-radius: 0 0 253px 10px;
  }
`;

export const ContainerHeaderLinksUnt = styled.div`
  margin-left:20px;
  figure {
    display: flex;
    gap: 10px;
  }

  .navlink {
    display: flex;
    align-items: center;
  }

  .active {
    border-bottom: 2px solid #fcfa51;
    color: #fcfa51;
  }
  .navlink:hover {
    border-bottom: 2px solid white;
  }
`;

export const ContainerHeaderSignOff = styled.div`
  display: flex;
  align-items: center;

  figure {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;

    img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }
  }
`;
