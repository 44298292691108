import React from 'react';
import { FooterContainer } from './footerStyles';



const Footer = () => {
  return (
    <FooterContainer>
        <h2>&copy; <a href="https://conexion21.com.co/">Todos los Derechos Reservados</a></h2>
    </FooterContainer>
  );
}

export default Footer;
