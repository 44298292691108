/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import styled from 'styled-components';
import logowhatsapp from '../assets/whatsapp.png';

const FloatingButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px; /* Cambio a la izquierda */
  z-index: 1000;
`;

const FloatingButton = styled.button`
  color: rgb(255, 255, 255);
  background-color: rgb(61, 155, 233,0.4);
  border-radius: 30px;
  border: none; /* Quitamos el borde */
  padding: 10px;
  display: flex;
  align-items: center;
`;

const BubbleText = styled.h2`
  font-family: heebo-medium, HelveticaNeueW01-45Ligh;
  font-size: 14px;
  margin-left: 10px;
  color:black ;
`;

const FloatingButtonImage = styled.img`
  width: 50px;
  border-radius: 0;
  fill: rgb(255, 255, 255);
  flex-shrink: 0;
`;

const FloatingButtonComponent = () => {
  return (
    <FloatingButtonWrapper>
      <a href="https://wa.me/3133663699" target='_blank'>
        <FloatingButton id="minimized-chat">
          <FloatingButtonImage src={logowhatsapp} alt="WhatsApp" />
          <BubbleText>¿En qué te podemos ayudar?</BubbleText>
        </FloatingButton>
      </a>
    </FloatingButtonWrapper>
  );
}

export default FloatingButtonComponent;
