import styled, { keyframes } from 'styled-components';

// Colores de la compañía
const primaryColor = '#1798f5';
export const accentColor = '#fcfa51';

// Animación para los clientes
const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
export const Title = styled.h2`
  color:  #1798f5;
  font-size: 34px;
  display: flex;
  justify-content: center;
  font-weight: bold;

`;
export const ClienteContainer = styled.div`
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items:center ;
  animation: ${fadeInAnimation} 0.8s ease;

  img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
    object-fit: contain;
    border-radius:10px ;
  }

  h3 {
    color: ${primaryColor};
    font-size: 20px;
    margin: 0;
    @media screen and (max-width: 400px) {
      font-size: 16px;
    }
  }
  &:hover {
    img {
      transform: scale(1.5) translateY(-20px); /* Aumentar el tamaño al 120% en el hover */
    }
  }
  @media screen and (max-width: 400px) {
           
        }
`;
