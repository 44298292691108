import React from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import estudiosImage from '../../assets/estudios.jpg'
import { scroller } from 'react-scroll';
export const ModalInterventorias = ({ showInterventorias, setShowInterventorias }) => {
  const handleScrollToSection = (section) => {
    const offset = -80;
    scroller.scrollTo(section, {
      duration: 500,
      smooth: 'easeInOutQuart',
      offset,
    });
    setShowInterventorias(false)
  };
  return (
    <Modal
      show={showInterventorias}
      onHide={() => setShowInterventorias(false)}
      className='modal-xl'
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          <h1 className='text-capitalize fw-bold'>Interventorías</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-light px-3'>
        <section className='d-flex justify-content-around align-items-center'>
          <div className='d-flex mt-5'>
            <img src={estudiosImage} alt="Redes de Telecomunicaciones" className='m-3 w-50 rounded' />
            <div>
              <h3 className='fs-3 text-capitalize fw-bold'>Interventorías</h3>
              <hr className="my-2 border border-warning" style={{ width: "100%" }} />
              <p className='mt-3 text-justify'>
              Nuestro servicio de Interventoría va más allá de la supervisión convencional. Nos convertimos en los ojos y oídos de tu proyecto, asegurando que cada detalle se desarrolle según lo planificado. Nuestro equipo de expertos trabaja en estrecha colaboración con tu equipo, brindando un control riguroso de calidad y cumplimiento. Desde el inicio hasta la finalización del proyecto, nuestra Interventoría se enfoca en garantizar la excelencia en la ejecución, la gestión eficiente de recursos y la resolución proactiva de cualquier desafío que pueda surgir. Confía en nosotros para llevar tu proyecto a un nivel superior de éxito.
              </p>
            </div>
          </div>
        </section>

        <ModalFooter>
          <button className='btn btn-primary'onClick={()=>handleScrollToSection("contacto")}>Contáctanos</button>
          <button className='btn btn-secondary' onClick={() => setShowInterventorias(false)}>Volver</button>
        </ModalFooter>
      </Modal.Body>
    </Modal>
  );
};
