import styled from "styled-components";

// Estilos para el título
export const Title = styled.h2`
  color: #fff; /* Azul más oscuro */
  font-size: 34px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  position: relative;
  
  /* Agregar línea debajo con sombra */
  &:after {
    content: '';
    position: absolute;
    left: 50%; /* Ajustar el ancho de la línea al 30% */
    bottom: -5px;
    width: 30%; /* Ancho de la línea al 30% */
    height: 2px;
    background-color: #fff; /* Azul más oscuro */
    box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2); /* Agregar sombra */
    transform: translateX(-50%); /* Centrar la línea */
  }
`;
// Estilos para el formulario y el fondo
export const ContactFormContainer = styled.div`
  background: linear-gradient(to right, #0e4ca5, #3383ca, #75b9e3);
  
  section {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 20px;
    background: #eaf4ff; /* Azul claro para el fondo del formulario */
  }
`;

// Estilos para los contenedores de filas (Rows)
export const Rows = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  /* Estilos adicionales para los divs dentro de las filas */
  & > div {
    width: calc(50% - 8px); /* Ancho del 50% de la columna, restar 8px para el espacio entre columnas */
  }
`;

// Estilos para etiquetas de formulario
export const FormLabel = styled.label`
  width: 100%; /* Ocupa todo el ancho de la columna */
  margin-bottom: 8px;
  font-weight: bold;
  color: #0e4ca5; /* Azul más oscuro */
`;

// Estilos para los campos de entrada del formulario
export const FormInput = styled.input`
  width: 100%; /* Ocupa todo el ancho de la columna */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

// Estilos para el área de texto del formulario
export const FormTextArea = styled.textarea`
  width: 100%; /* Ocupa todo el ancho de la columna */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

// Estilos para los botones del formulario
export const FormButton = styled.button`
  width: 50%; /* Ocupa todo el ancho de la columna */
  background-color: #0e4ca5; /* Azul más oscuro */
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 8px;

  &:hover {
    background-color: #00549c; /* Un tono más oscuro para el efecto hover */
  }
`;
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;