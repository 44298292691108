import { styled, keyframes } from 'styled-components';
const enlargeAnimation = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2); 
  }
`;

export const Title = styled.h2`
  color:  #1798f5;
  font-size: 34px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin-top: 30px;


  /* Agregar línea debajo con sombra */
  &:after {
    content: '';
    position: absolute;
    left: 50%; /* Ajustar el ancho de la línea al 30% */
    bottom: -5px;
    width: 30%; /* Ancho de la línea al 30% */
    height: 2px;
    background-color: #1798f5;
    box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2); /* Agregar sombra */
    transform: translateX(-50%); /* Centrar la línea */
  }
`;


export const DivServicios = styled.div`
  display:flex ;
  flex-wrap: wrap;
  justify-content: center;
  margin-top:15px ;
  align-items:center ;
  padding-left:10px ;

`
export const SectionServicios = styled.section`
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 20px);
    justify-content: center;
    align-items:center ;
    section{
        width: 60%;
        height:280px;
        background: linear-gradient(to right,#1798f5, #25eaf7);
        margin-bottom:30px ;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center ;
        border-radius:20px 20px 0px 20px;
        cursor: pointer;
        h2{
          text-align:center ;
          text-transform:uppercase;
        }
    }
&:hover img {
  animation: ${enlargeAnimation} 0.3s forwards;
    }
 

    img {
        border-radius:60%;
        width: 60%;
        
  transition: all 0.3s ease;
}
    h2 {
        color:#fcfa51 ;
        font-weight:bold ;
        font-size:22px;
        margin-top:10px ;
    }
    @media screen and (max-width: 769px) {
        width: calc(50% - 20px);
  }
    @media screen and (max-width: 400px) {
        width: 100%;
  }
`